import React from 'react';

import Layout from '../components/layout';
import ThanksBanner from '../components/Thanks/Banner';

const Thanks = () => {
  return (
    <Layout footerColor={true}>
      <ThanksBanner />
    </Layout>
  );
}

export default Thanks;